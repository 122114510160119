import $ from 'jquery';
import { Controller } from 'framewerk';
import { STATES } from '../shared';

/**
 * The initialization function for creating the `r18-lab-relations` Controller.
 */
export function labRelations(): Controller {
  let mainLabId: string;
  let mainSupervisorId: string;

  /**
   * The name of the controller.
   */
  const name: string = 'r18-lab-relations';

  /**
   * Selector strings for the `r18-lab-relations` Controller.
   */
  const targets = Controller.getTargets({
    wrapper: `[data-${name}-wrapper]`,
    inactive: `[data-${name}-inactive]`,
    bypassChecklist: `[data-${name}-bypass-checklist]`,
    activeToggle: `input[data-${name}-active-toggle]`,
    roleId: `select[data-${name}-role-id]`,
    mainLabId: `select[data-${name}-main-lab-id]`,
    rowSelect: `input[data-${name}-row-select]`,
    mainSupervisorFieldWrapper: `[data-${name}-main-supervisor-field-wrapper]`,
    mainSupervisorId: `select[data-${name}-main-supervisor-id]`,
    mainLurField: `input[data-${name}-main-lur-field]`,
    row: `tr[data-${name}-row]`,
    hasOnePosition: `input[data-${name}-has-one-position]`
  }) as {
    wrapper: HTMLElement[];
    inactive: HTMLElement[];
    bypassChecklist: HTMLInputElement[];
    activeToggle: HTMLInputElement[];
    roleId: HTMLInputElement[];
    mainLabId: HTMLSelectElement[];
    rowSelect: HTMLInputElement[];
    mainSupervisorFieldWrapper: HTMLElement[];
    mainSupervisorId: HTMLSelectElement[];
    mainLurField: HTMLInputElement[];
    row: HTMLTableRowElement[];
    hasOnePosition: HTMLInputElement[];
  };

  /**
   * Events created for the `r18-lab-relations` Controller.
   */
  const events = {
    windowLoad: () => {
      mainLabId = targets.mainLabId[0].value;
      mainSupervisorId = targets.mainSupervisorId[0].value;

      targets.row.forEach(prepareRow);

      $(targets.roleId[0]).on('change', event => {
        const isVisible =
          event.target.value !== '2' && event.target.value !== '5';
        setWrapperState(isVisible);
      });

      $(targets.mainLabId[0]).on('change', event => {
        mainLabId = event.target.value;
        targets.row.forEach(row => {
          if (row.dataset.labId === mainLabId) {
            toggleRow(
              row,
              ['2', '5'].every(id => id !== targets.roleId[0].value)
            );

            const $supervisorIdField = $(row).find('select[data-parent-id]')[0];
            const $mainSupervisorIdField = $(targets.mainSupervisorId[0]);
            const options = Array.from($supervisorIdField.children).map(
              (option: HTMLOptionElement) => ({
                text: option.innerText,
                id: option.value
              })
            );

            $mainSupervisorIdField.empty();

            options.forEach(option => {
              $mainSupervisorIdField.append(
                new Option(option.text, option.id, false, false)
              );
            });

            $mainSupervisorIdField.trigger('change');
          }
        });
      });

      $(targets.mainSupervisorId[0]).on('change', event => {
        mainSupervisorId = event.target.value;
        $(getMainLabRow())
          .find('select[data-parent-id]')
          .val(mainSupervisorId)
          .trigger('change');
      });

      $(targets.rowSelect).on('change', ({ target }) => {
        const hasUncheckedMainLab =
          target.dataset.r18LabRelationsLabId === targets.mainLabId[0].value &&
          !target.checked;

        const hasAnySelectedLabs = targets.rowSelect.some(
          target => target.checked
        );

        if (!hasAnySelectedLabs || hasUncheckedMainLab) {
          $(targets.mainLabId[0]).val('').trigger('change');
          $(targets.mainSupervisorId[0]).val('').trigger('change');
        }
      });

      targets.activeToggle[0].addEventListener('change', event => {
        const eventTarget = event.target as HTMLInputElement;

        if (!eventTarget.checked) {
          targets.row.forEach(row => {
            setRowState(row, false);
          });
        }
      });
    }
  };

  return new Controller({ name, targets, events });

  /////////////////

  function setWrapperState(isVisible: boolean) {
    const wrapper = targets.wrapper[0];
    const inactive = targets.inactive[0];

    if (!isVisible) {
      targets.row.forEach(row => {
        toggleRow(row, false);
      });
    }

    inactive.classList.toggle(STATES.isHidden, isVisible);
    wrapper.classList.toggle(STATES.isHidden, !isVisible);

    targets.bypassChecklist[0].value = (!isVisible).toString();

    targets.mainLurField.forEach(target => {
      target.disabled = isVisible;
    });

    targets.mainSupervisorId[0].disabled = isVisible;
    targets.mainSupervisorFieldWrapper[0].classList.toggle(
      STATES.isHidden,
      isVisible
    );

    targets.wrapper[0]
      .querySelectorAll<HTMLInputElement | HTMLSelectElement>('input, select')
      .forEach(input => {
        console.log(input);
        input.disabled = !isVisible;
      });
  }

  function prepareRow(row: HTMLTableRowElement) {
    const checkbox = row.querySelector<HTMLInputElement>(
      'input[type=checkbox]'
    );

    const supervisorSelect = row.querySelector<HTMLSelectElement>(
      `select[data-parent-id]`
    );

    setRowState(row, checkbox.checked);

    checkbox.addEventListener('change', event => {
      const eventTarget = event.target as HTMLInputElement;
      setRowState(row, eventTarget.checked);
      setHasOnePositionInput(
        targets.hasOnePosition[0] as HTMLInputElement,
        hasPositionSelected(targets.row as HTMLTableRowElement[])
      );
    });

    $(supervisorSelect).on('select2:select', function (event) {
      const eventTarget = event.target as HTMLInputElement;
      // if this is the user's main lab, update the appropriate field at the top
      if (row.dataset.labId === mainLabId) {
        mainSupervisorId = eventTarget.value;
        $(targets.mainSupervisorId[0]).val(eventTarget.value).trigger('change');
      }
    });
  }

  function toggleRow(row: HTMLTableRowElement, isActive: boolean) {
    // prettier-ignore
    row.querySelector<HTMLInputElement>('input[type="checkbox"]').checked = isActive;
    setRowState(row, isActive);
  }

  function setRowState(row: HTMLTableRowElement, isSelected: boolean) {
    row
      .querySelectorAll<HTMLSelectElement & HTMLInputElement>(
        'select, input[type=hidden]'
      )
      .forEach(element => {
        element.disabled = !isSelected;
      });

    if (!isSelected) {
      // prettier-ignore
      row.querySelector<HTMLInputElement>('input[type="checkbox"]').checked = false;
    }

    const destroyLurField = row.querySelector<HTMLInputElement>(
      `input[data-destroy-lur]`
    );

    destroyLurField.disabled = isSelected;
    destroyLurField.value = (!isSelected).toString();

    const hiddenLurIdField = row.querySelector<HTMLInputElement>(
      `input[data-lur-id-hidden]`
    );

    if (hiddenLurIdField) {
      hiddenLurIdField.disabled = false;
    }
  }

  function setHasOnePositionInput(
    input: HTMLInputElement,
    isSelected: boolean
  ) {
    input.value = isSelected ? isSelected.toString() : '';
    input.required = mainLabId !== '2' && mainLabId !== '5';
  }

  function hasPositionSelected(rows: HTMLTableRowElement[]) {
    return rows.some(
      row =>
        row.querySelector<HTMLInputElement>('input[type="checkbox"]')?.checked
    );
  }

  function getMainLabRow() {
    return targets.row.find(row => row.dataset.labId === mainLabId);
  }
}
